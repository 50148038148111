import React from "react";
// import "./style.css"
import logo from "../../assets/img/logoMiysis.png"

export default function Footer() {

    return (
        <div className="frame" style={{zIndex: "100", position: "absolute", bottom: "0", width:"100%",height:'40px', background: '#FFF'}}>
            <div style={{display:'flex', alignItems: 'center'}}>
                <img width="60px" height="30px" alt="logo-Miysis" src={logo}/>
                <p style={{fontSize:'12px'}}>Virtual tour by Miysis</p>
            </div>
        </div>
    )
}
