import L from 'leaflet';

const iconPerson = new L.Icon({
    iconUrl: require('../assets/img/dppLogo.png'),
    iconRetinaUrl: require('../assets/img/dppLogo.png'),
    iconAnchor: null,
    popupAnchor: [-3,-30],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(55, 45),
    className: 'leaflet-div-icon'
});

export { iconPerson };