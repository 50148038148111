import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';

window.getHashScene = function() {
  var hash = document.location.hash.replace('#/', '')
  if (hash !== '') {
    return hash
  }
  return null
}

window.setHashScene = function(scene) {
  document.location.hash = `#/${scene}`
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
