import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import zIndex from "@material-ui/core/styles/zIndex";
import './style.css'
import Fade from '@material-ui/core/Fade';
import Backdrop from "@material-ui/core/Backdrop";
import { Map, Marker, Popup, TileLayer} from 'react-leaflet'
import {  iconPerson  } from '../Icon';
import {Trans} from "react-i18next";

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        outline: 'none !important',
        width: `92vw`,
        height: `70vh`
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #bda25a',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function LocalisationModal() {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const position = [49.616194, 6.106283];

    return (
        <div>
            <button id="mapButton" style={{width: "300px"}} type="button" onClick={handleOpen}>
                <Trans i18nKey="menu.location.cta.label">
                    Localisation
                </Trans>
            </button>
            <Modal
                root={{overlay: {zIndex: '12'}}}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
                style={{outline:'none'}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500,}}
            >
                <Fade in={open}>
                    <div id="modal" style={modalStyle} className={classes.paper}>
                        <a style={{float:"right", marginTop:"5px", color:"#bda25a"}} width={"10px"} onClick={handleClose}><i className="material-icons" style={{float:"right", cursor:"pointer", marginRight:"-50%"}}>close</i></a>
                        {/* <img src={logo} width={'125vw'} alt={"logoTriside"}/>*/}
                        {/*<h2>Projet disponible milieu 2021</h2>*/}
                        <div id="simple-modal-description-map">
                            <Map style={{ height: "65vh", width: "90vw"}} center={position} zoom={17}>
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                                />
                                <Marker icon={ iconPerson } position={position}>
                                    <Popup>Domaine du Petit Parc</Popup>
                                </Marker>
                            </Map>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
