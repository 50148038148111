import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import zIndex from "@material-ui/core/styles/zIndex";
import './style.css'
/*import logo from '../../assets/img/Triside-color-5.jpg'*/
import Fade from '@material-ui/core/Fade';
import Backdrop from "@material-ui/core/Backdrop";
import info from "../../assets/img/infodpp1.png"
import mapFR from "../../assets/img/mapdpp_fr.png"
import mapEN from "../../assets/img/mapdpp_en.png"
import quote from "../../assets/img/Citation fin.png"
import vue from "../../assets/img/vueaerienne.jpg"
import {Trans, useTranslation} from "react-i18next";

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        outline: 'none !important'
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function InfoModal() {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { i18n } = useTranslation();

    return (
        <div>
            <button id="infoButton" style={{fontFamily: "IntroBold",color: '#bda25a', maxWidth:'300px'}} type="button" onClick={handleOpen}>
                <Trans i18nKey="menu.information.cta.label">
                    Information
                </Trans>
            </button>
            <Modal
                root={{overlay: {zIndex: '12'}}}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
                style={{outline:'none'}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500,}}
            >
                <Fade in={open}>
                <div id="modal"   style={{width: page ? "70vw" : '800px',top: `50%`, left: `50%`, transform: `translate(-50%, -50%)`, outline: 'none !important'}} className={classes.paper}>
                    <a style={{float:"right", marginTop:"5px", color:"#bda25a"}} width={"10px"} onClick={handleClose}><i className="material-icons" style={{float:"right", cursor:"pointer"}}>close</i></a>
                    {/*<img src={logo} width={'125vw'} alt={"logoTriside"}/>*/}
                    {/*<div className="redDivider"></div>*/}
                    <h2>Domaine Petit Parc</h2>
                    {!page && (
                    <div id="simple-modal-description">
                        <p className="description">
                            <span style={{fontFamily: "IntroBold", textTransform: "uppercase", color: "#bda25a"}}>
                                <Trans i18nKey="infos.description.part1">
                                    Idéalement situé au coeur du quartier de Belair,
                                </Trans>
                            </span>
                            <Trans i18nKey="infos.description.part2">
                                au sein d’un parc verdoyant au calme, le « Domaine Petit Parc » offre un cadre de vie empreint de sérénité à quelques pas du centre de la capitale. Conçu selon un cahier des charges des plus exigeants avec le soutien d’une équipe d’experts éprouvés, tous sensibles à la qualité architecturale, le projet « Domaine Petit Parc » reflète la volonté de créer un lieu de vie d’exception en milieu urbain. Implanté dans un vaste parc privatif, le projet se compose de trois résidences d’architecture contemporaine et intemporelle, comprenant 65 logements d’exception, allant du studio au penthouse.
                            </Trans>
                        </p>
                        <img className="cartedpp" src={i18n.resolvedLanguage === 'fr' ? mapFR : mapEN} alt="carte domaine petit parc"/>
{/*                        <button className={"pageBtn"} onClick={() => setPage(true)}>Page 2</button>*/}
                    </div>
                    )
                    }
                    {page && (
                        <div style={{width: "70vw"}} id="simple-modal-description">
                            <img src={vue} width={"100%"} alt={"vue aérienne"}/>
                            <button className={"pageBtn"} onClick={() => setPage(false)}>Page 1</button>
                        </div>
                    )
                    }
                </div>
                </Fade>
            </Modal>
        </div>
    );
}
