import React, {Component, createRef} from 'react';
import logo from './logo.svg';
import './App.css';
import Tour from './components/Tour/Tour'
import Sidebar from "./components/Sidebar/Sidebar";
import background from "./assets/img/Fond.jpg"
import dpp from "./assets/img/DPP_rvb.png"
import { withTranslation } from 'react-i18next';

class App extends Component {
  state = {
    mounted: false,
    isLoading: true
  }

  lngs = {
    en: { nativeName: 'English', shortName: 'EN' },
    fr: { nativeName: 'Français', shortName: 'FR' }
  };

  componentDidMount() {
    console.log(this.container)
    this.setState({mounted: true})
    setTimeout(
        function () {
            this.setState({isLoading: false});
        }
            .bind(this),
            1500
    )
  }

  container = createRef();
  render() {
    let { isLoading } = this.state;
    const { mounted } = this.state;
    const { i18n } = this.props;

    return (
        <div>
          {isLoading ? (
              <div style={{
                backgroundImage: `url(${background})`,
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }} className={`App ${isLoading ? 'fadeIn' : 'fadeOut'}`} ref={this.container}>
                <img width="15%" alt="DPP Logo" src={dpp}/>
              </div>
          ) : (
              <div className="App" ref={this.container}>
                {mounted && <Tour container={this.container}/>}
                <div style={{ position: 'absolute', top: 0, right: 0}}>
                  {Object.keys(this.lngs).map((lng) => (
                      <button
                          className="lang-switcher button"
                          key={lng}
                          style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal', }}
                          type="submit"
                          onClick={() => i18n.changeLanguage(lng)}
                      >
                        {this.lngs[lng].nativeName}
                      </button>
                  ))}
                </div>
              </div>
          )}
        </div>
    );
  }
}

export default withTranslation()(App);
