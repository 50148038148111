import React, {Component, createRef, useEffect} from "react"
import { createPortal } from "react-dom";
import Krpano from "react-krpano";
import Sidebar from "../Sidebar/Sidebar";
import Portal from "@material-ui/core/Portal";
import Frame from "../Entreprise/Frame";
import Footer from "../Footer/Footer";
import Home from "../Home/Home"
import './style.css'
import {withTranslation} from "react-i18next";

class Tour extends Component {
    state = {
        scenes: []
    };

    krpano = createRef();

    componentDidMount() {
        const { embedpano } = window;
        const {i18n} = this.props;

        console.log(`${process.env.PUBLIC_URL}/vtour/tour_${i18n.resolvedLanguage}.xml`)
        embedpano({
            xml: `${process.env.PUBLIC_URL}/vtour/tour_${i18n.resolvedLanguage}.xml`,
            target: 'pano',
            html5: 'only',
            passQueryParameters: true,
            onready: this.onReady,
            id:"krpanoSWFObject",
            consolelog:true
        });
    }

    onReady = (krpano) => {
        window.krpano = krpano

        setTimeout(() => {
            this.setState({ scenes: krpano.get('scene').getArray().map(({name, title, thumburl}) => ({ name, title, thumburl })) })
        }, 1000)
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.t !== prevProps.t) {
            const { krpano, embedpano } = window;
            const {i18n} = this.props;

            krpano.unload();

            embedpano({
                xml: `${process.env.PUBLIC_URL}/vtour/tour_${i18n.resolvedLanguage}.xml`,
                target: 'pano',
                html5: 'only',
                passQueryParameters: true,
                onready: this.onReady,
                id:"krpanoSWFObject",
                consolelog:true
            });
        }
    }

    render() {
        const { container } = this.props;
        const { scenes } = this.state;

        if (!container.current) {
            return null;
        }

        return (
            <div id='pano' style={{width: '100vw', height: '100vh'}}>
                {/*<Krpano ref={this.krpano} loading="Loading..." xml={`${process.env.PUBLIC_URL}/vtour/tour.xml`}/>*/}
                <Portal container={container.current}>
                    <Sidebar scenes={scenes} />
                </Portal>
                <Frame/>
                <Footer/>
                {/*<Home/>*/}
            </div>
        )

    }
}

export default withTranslation()(Tour)
