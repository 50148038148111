import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import zIndex from "@material-ui/core/styles/zIndex";
import './style.css'
import logo from '../../assets/img/logo.png'
import Fade from '@material-ui/core/Fade';
import Backdrop from "@material-ui/core/Backdrop";
import cdcl from "../../assets/img/cdclgold.png"
import delli from "../../assets/img/dellizotti.png"
import au21 from "../../assets/img/A&U21.png"
import luzi from "../../assets/img/luzi.png"
import garden from "../../assets/img/AGENCE-GARDEN DESIGN.png"
import sa from "../../assets/img/S&A.png"
import sa2 from "../../assets/img/S&A2.png"
import jse from "../../assets/img/jse.png"
import d2s from "../../assets/img/D2S_logo.png"
import quote from "../../assets/img/Citation fin.png"
import {Trans} from "react-i18next";

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        outline: 'none !important'
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 850,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function Intervenants() {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <button id="infoButton" style={{fontFamily: "IntroBold",color: '#bda25a', maxWidth:'300px'}} type="button" onClick={handleOpen}>
                <Trans i18nKey="menu.partnership.cta.label">
                    Intervenants
                </Trans>
            </button>
            <Modal
                root={{overlay: {zIndex: '12'}}}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
                style={{outline:'none'}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500,}}
            >
                <Fade in={open}>
                    <div id="modalInter" style={modalStyle} className={classes.paper}>
                        <a style={{float:"right", marginTop:"5px", color:"#bda25a"}} width={"10px"} onClick={handleClose}><i className="material-icons" style={{float:"right", cursor:"pointer"}}>close</i></a>
                        {/*<img src={logo} width={'125vw'} alt={"logoTriside"}/>*/}
                        {/*<div className="redDivider"></div>*/}
                        <h2>Intervenants</h2>
                        <div id="simple-modal-description-inter">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="partenariat">
                                        <h4>
                                            <Trans i18nKey="partnership.title">
                                                Le partenariat CDCL-Dellizotti : un indéniable gage de qualité
                                            </Trans>
                                        </h4>
                                        <div className="dividerGold"></div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <img width="125vw" height={"60px"} src={logo}/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="infoEntreprise">
                                                        <p>24, rue Léon Laval<br/>
                                                            L-3372 Leudelange
                                                            <br/>
                                                            <a href={"www.cdclux.com"} target={"_blank"}>cdclux.com</a>
                                                        </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <img width="125vw" height={"auto"} src={delli}/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="infoEntreprise">
                                                        <p>
                                                            75, Montée Krakelshaff
                                                            L-3235 Bettembourg
                                                            <br/>
                                                            <a href={"www.dellizotii.lu"} target={"_blank"}>dellizotti.lu</a>
                                                        </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className={"descCdclDelli"}>
                                                <Trans i18nKey="partnership.description">
                                                    Le partenariat CDCL-Dellizotti a réussi le pari de créer un projet d’exception au cœur du quartier de Belair : « Le Domaine Petit Parc » Ces deux entreprises de construction ancrées sur le marché luxembourgeois depuis plusieurs générations partagent une même sensibilité à la beauté architecturale, au choix des matériaux, à la qualité des finitions et au bien-être inconditionnel des occupants. Par leur souci permanent de rigueur, d’intégrité et de précision, CDCL et Dellizotti garantissent des habitations de grande qualité, innovantes et énergétiquement efficientes.
                                                </Trans>
                                            </p>
                                        </div>
                                        <div className="dividerDark"/>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className={"col-md-6 divideInfo"}>
                                                    <h4>
                                                        <Trans i18nKey="partnership.subtitle.architect">
                                                            ARCHITECTE
                                                        </Trans>
                                                    </h4>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <img height="50px" src={au21} alt="logoAU21"/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="infoEntreprise">
                                                                    <p style={{margin: "0"}}>
                                                                        42A, Place Guillaume II
                                                                        <br/>
                                                                        L-1648 Luxembourg
                                                                        <br/>
                                                                        au21.lu
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="dividerDarkLight"/>
                                                    </div>
                                                    <div className="col-md-6 divideInfo">
                                                        <h4>
                                                            <Trans i18nKey="partnership.subtitle.staticDesignOffice">
                                                                BUREAU D’ÉTUDES STATIQUE
                                                            </Trans>
                                                        </h4>
                                                        <div className="row">
                                                                    <div className="col-md-6">
                                                                        <img style={{marginRight: '2%'}} src={sa2} alt="logoSA"/>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="infoEntreprise">
                                                                            <p style={{margin: "0", width: "270px"}}>
                                                                                8, rue des Girondins<br/>
                                                                                L-1626 Luxembourg<br/>
                                                                                schroeder.lu
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                        </div>
                                                        <div className="dividerDarkLight"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/*<div className="col-md-12">
                                                <h4>ARCHITECTE D’INTÉRIEUR</h4>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <img height="50px" src={luzi} alt="logoLUZI"/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="infoEntreprise">
                                                                    <p style={{margin: "0", width: "270px", paddingLeft: "4%"}}>
                                                                        2 Rue du Père Jacques Brocquart
                                                                        L-1280 Luxembourg
                                                                        caroline@carolineluzi.com
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                    </div>
                                                </div>
                                            </div>*/}
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className={"col-md-6 divideInfo"}>
                                                        <h4>
                                                            <Trans i18nKey="partnership.subtitle.interiorDesigner">
                                                                ARCHITECTE D'INTÉRIEUR
                                                            </Trans>
                                                        </h4>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <img height="50px" src={luzi} alt="logoLUZI"/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="infoEntreprise">
                                                                    <p style={{margin: "0"}}>
                                                                        2 rue du Père Jacques Brocquart
                                                                        <br/>
                                                                        L-1280 Luxembourg
                                                                        <br/>
                                                                        caroline@carolineluzi.com
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="dividerDarkLight"/>
                                                    </div>
                                                    <div className="col-md-6 divideInfo">
                                                        <h4>
                                                            <Trans i18nKey="partnership.subtitle.technicalDesignOffice">
                                                                BUREAU D’ÉTUDES TECHNIQUE
                                                            </Trans>
                                                        </h4>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <img style={{marginRight: '2%', width: '118px'}}
                                                                     src={jse} alt="logoJSE"/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="infoEntreprise">
                                                                    <p style={{margin: "0", width: "270px"}}>
                                                                        13 A avenue Gaston Diderich<br/>
                                                                        L-1420 Luxembourg<br/>
                                                                        jse.lu
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="dividerDarkLight"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/*<div className="col-md-12">
                                                <h4>ARCHITECTE PAYSAGISTE</h4>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <img width="125vw" src={garden} alt="logoGARDEN"/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="infoEntreprise">
                                                                    <p style={{margin: "0", width: "270px"}}>
                                                                        104 rue Saint-Maur<br/>
                                                                        75011 Paris - France<br/>
                                                                        christophe-gautrand.com
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                    </div>
                                                </div>
                                            </div>*/}
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className={"col-md-6 divideInfo"}>
                                                        <h4>
                                                            <Trans i18nKey="partnership.subtitle.landscapeArchitect">
                                                                ARCHITECTE PAYSAGISTE
                                                            </Trans>
                                                        </h4>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <img width={"134px"} src={garden} alt="logoGARDEN"/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="infoEntreprise">
                                                                    <p style={{margin: "0"}}>
                                                                        104 rue Saint-Maur<br/>
                                                                        75011 Paris - France<br/>
                                                                        christophe-gautrand.com
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*<div className="dividerDarkLight"/>*/}
                                                    </div>
                                                    <div className="col-md-6 divideInfo">
                                                        <h4>
                                                            <Trans i18nKey="partnership.subtitle.acoustician">
                                                                ACOUSTICIEN
                                                            </Trans>
                                                        </h4>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <img style={{marginRight: '2%', height: '47px'}}
                                                                     src={d2s} alt="logoD2S"/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="infoEntreprise">
                                                                    <p style={{margin: "0", width: "270px"}}>
                                                                        Jules Vandenbemptlaan 71<br/>
                                                                        B-3001 Heverlee - Belgique<br/>
                                                                        d2sint.com
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*<div className="dividerDarkLight"/>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="row">
                                    <div className="col-md-12">
                                        <h4>BUREAU D’ÉTUDES STATIQUE</h4>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <img width="125vw" style={{filter: "grayscale(1)"}} src={sa} alt="logoSA"/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="infoEntreprise">
                                                            <p style={{margin: "0", width: "270px"}}>
                                                                8, rue des Girondins<br/>
                                                                L-1626 Luxembourg<br/>
                                                                schroeder.lu
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dividerGoldLight"/>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4>BUREAU D’ÉTUDES TECHNIQUES</h4>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <img width="125vw" style={{filter: "grayscale(1)"}} src={jse} alt="logoJSE"/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="infoEntreprise">
                                                            <p style={{margin: "0", width: "270px"}}>
                                                                13 A, Avenue Gaston Diderich<br/>
                                                                L-1420 Luxembourg<br/>
                                                                jse.lu
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                            </div>
                                        </div>
                                    </div>
                                </div>*/}
                                <div className="dividerGold"/>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4 style={{color: "#bda25a"}}>
                                            <Trans i18nKey="partnership.subtitle.marketing">
                                                COMMERCIALISATION
                                            </Trans>
                                        </h4>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <img width="125vw" src={logo}/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="infoEntreprise">
                                                            <p>24, rue Léon Laval<br/>
                                                                L-3372 Leudelange
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="infoEntreprise">
                                                            <p style={{fontWeight: "bold", paddingTop: "8%"}}>
                                                                (+352) 48 59 59 300
                                                                contact@cdclpromotions.com
                                                                cdclux.com
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="quote">
                                    <img src={quote} alt="citation"/>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
