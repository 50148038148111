import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from "./lang/en.json"
import frTranslation from "./lang/fr.json"

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        resources: {
            en: { translation: enTranslation },
            fr: { translation: frTranslation },
        },
        lng: 'fr', // default language
        fallbackLng: 'en', // fallback language
        interpolation: {
            escapeValue: false, // react already safe from xss
        },
    });

export default i18n;