import React, {Component} from 'react';
import home from "../../assets/img/home.png"
import "./style.css"
class Home extends Component {
    render() {
        return (
            <div className="home">
                <a href="../">
                    <img width="65px" src={home}/>
                </a>
            </div>
        );
    }
}

export default Home;