import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import './style.css'
import {Trans, withTranslation} from "react-i18next";

class LightboxExample extends Component {
    constructor(props) {
        super(props);

        console.warn(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
        };
    }


    render() {
        const { photoIndex, isOpen } = this.state;
        const {i18n} = this.props;

        let images_fr = require.context(`../../assets/img/gallery_fr`, false, /\.jpe?g$|\.png$/i);
        let images_en = require.context(`../../assets/img/gallery_en`, false, /\.jpe?g$|\.png$/i);

        const imagesUrl = i18n.resolvedLanguage === 'fr' ? images_fr.keys().map(key => images_fr(key)) : images_en.keys().map(key => images_en(key));
        const imagesLength = i18n.resolvedLanguage === 'fr' ? images_fr.length : images_en.length;

        console.warn( imagesUrl);

        return (
            <div className="slider">
                <button id="galleryButton" style={{width: "300px"}} type="button" onClick={() => this.setState({ isOpen: true })}>
                    <Trans i18nKey="menu.gallery.cta.label">
                        Galerie
                    </Trans>
                </button>

                {isOpen && (
                    <Lightbox
                        mainSrc={imagesUrl[photoIndex]}
                        imageTitle={false}
                        nextSrc={imagesUrl[(photoIndex + 1) % imagesLength]}
                        reactModalStyle={{overlay: { zIndex:'12000' }}}
                        prevSrc={imagesUrl[(photoIndex + imagesLength - 1) % imagesLength]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + imagesUrl.length - 1) % imagesUrl.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % imagesUrl.length,
                            })
                        }
                    />
                )}
            </div>
        );
    }
}

export default withTranslation()(LightboxExample)
