import React from "react";
import "./style.css"
import logo from "../../assets/img/logo.png"
import cdcl from "../../assets/img/cdclgold.png"
import delli from "../../assets/img/dellizotti.png"

export default function Frame() {
    return (
        <div className="frame" style={{zIndex: "100", opacity: '0', backgroundColor: 'rgba(255,255,255,0.5)', position: "absolute", width:'250px', padding: '20px', bottom: "7%", right: "36px"}}>
            <div>
            <img width="200px" alt="logo-CDCL" src={cdcl}/>
            </div>
        </div>
    )
}
