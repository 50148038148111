import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from "@material-ui/core/Button";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import {Typography} from "@material-ui/core";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { createTheme } from '@material-ui/core/styles';
import logo from '../../assets/img/logo.png'
import cdcl from "../../assets/img/cdclgold.png"
import delli from "../../assets/img/dellizotti.png"

import './style.css'
import InfoModal from "../InfoModal/InfoModal";
import webicon from "../../assets/img/webicongrey.png";
import fb from "../../assets/img/fbgrey.png";
import Menu from "../Menu/Menu";
import Slideshow from "../Slideshow/Slideshow";
import Localisation from "../Localisation/Localisation";
import Contact from "../Contact/Contact";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import axios from "axios";
import logodpp from "../../assets/img/dppLogo.png"
import Intervenants from "../Intervenants/Intervenants";
import cdclgold from "../../assets/img/cdclgold.png";
import {Trans, useTranslation} from "react-i18next";

const sceneBloc = {
    fr: {
        'VISITE VIRTUELLES': [
            'Entrée Parc – Résidence 1','Rue Napoléon 1er – Résidence 5','Rue Nicolas Petit – Résidence 3','Parc – Résidence 3','Parc – Résidence 5','Couloir – Résidence 5','Lobby/Entrée – Résidence 5', 'Appartement SDB v1','Appartement SDB v2','Appartement séjour v1','Appartement séjour v2', 'Penthouse chambre v1','Penthouse chambre v2','Penthouse séjour A v1','Penthouse séjour A v2','Penthouse séjour B v1','Penthouse séjour B v2','Duplex chambre v1','Duplex chambre v2','Duplex séjour v1','Duplex séjour v2'
        ],
        /* 'HALL': ['pano6','pano7',],
         'APPARTEMENT': ['pano8','pano9','pano10','pano11',],
         'PENTHOUSE': ['pano12','pano13','pano14','pano15','pano16','pano17',],
         'DUPLEX': ['pano18','pano19','pano20','pano21']*/
    },
    en: {
        'VIRTUAL TOUR': [
            'Entrance Park - Residence 1','Napoleon 1st Street - Residence 5','Nicolas Petit Street - Residence 3','Park - Residence 3','Park - Residence 5','Main common corridor - Residence 5','Lobby/Entrance - Residence 5', 'Apartment Bathroom V1','Apartment Bathroom V2','Apartment Living Room V1','Apartment Living Room V2', 'Penthouse Bedroom V1','Penthouse Bedroom V2','Penthouse Living Room A V1','Penthouse Living Room A V2','Penthouse Living Room B V1','Penthouse Living Room B V2','Duplex Bedroom V1','Duplex Bedroom V2','Duplex Living Room V1','Duplex Living Room V2'
        ],
        /* 'HALL': ['pano6','pano7',],
         'APPARTEMENT': ['pano8','pano9','pano10','pano11',],
         'PENTHOUSE': ['pano12','pano13','pano14','pano15','pano16','pano17',],
         'DUPLEX': ['pano18','pano19','pano20','pano21']*/
    }
}

console.warn(sceneBloc['fr'])

const useStyles = makeStyles({
    list: {
        width: 150,
        boxShadow: 'none'
    },
    fullList: {
        width: 'auto',
    },
    paper: {
        backgroundColor: 'none'
    }
});

const theme = createTheme({
    overrides: {
        MuiExpansionPanelDetails: {
            root: {
                maxWidth: '350px !important'
            },
            modal: {
                maxWidth: '50% !important'
            }
        }
    }
})

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `50%`,
        left: `50%`,
        transform: `translate(-${top}%, -${left}%)`,
        outline: 'none !important'
    };
}



const formRef = React.createRef();

function sendMail(e) {
    e.preventDefault();
    const data = new FormData(formRef.current);
    const iterator = data.entries();
    let val;

    while (!(val = iterator.next()).done) {
        console.log(val.value)
    }
    axios.post(`${process.env.PUBLIC_URL}/controller/contact.php`, data)
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
    document.getElementById("idForm").reset();
    document.getElementById("sendButton").value = "Message envoyé !"
}



export default function Sidebar({scenes}) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });


    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [side]: open });
    };

    const goToScene = (scene) => {
        const { krpano } = window;
        console.log(scene)
        krpano.call(`loadscene('${scene}')`)
    }

    const sideList = side => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
        </div>
    );

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };


    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {

        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const fullList = side => (
        <div
            className={classes.fullList}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
        </div>
    );

    const { i18n, t} = useTranslation();

    return (
        <div>
            <div style={{position:'absolute', top:'36px', left:'36px', zIndex:"10"}}>
                <Button className={"logoBtn"} onClick={toggleDrawer('left', true)}><img style={{margin: "0px"}} width="64px" alt="logo dpp" src={logodpp}/><p>Domaine</p><p>Petit Parc</p></Button>
                <Drawer style={{zIndex:"11"}} open={state.left} onClose={toggleDrawer('left', false)}>
                    {sideList('left')}
                    <a style={{float:"right", marginTop:"5px", color:"#bda25a"}} width={"10px"} onClick={toggleDrawer('left',false)}><i className="material-icons" style={{float:"right", cursor:"pointer"}}>close</i></a>
                    {/*<img src={logo} alt={'CDCLLogo'} width={"140px"} style={{margin: "0 auto", marginTop:"5px", marginBottom:"15px"}}/>*/}
                    <h3 style={{margin: '0',padding: '0 21px',color: '#bda25a', fontFamily: 'Bifur', fontWeight: "normal"}}>Domaine Petit Parc</h3>
                    <h5 style={{padding: '5px 22px',color: '#676262', fontFamily: 'IntroBold', fontWeight: "normal", marginTop: '0px'}}>Luxembourg, Belair</h5>
                    <div className="divider"></div>
                    <InfoModal/>
                    <Localisation/>
                    {Object.keys(sceneBloc[i18n.resolvedLanguage]).map(bloc => (
                        <ExpansionPanel style={{ background: '#F7F4EF' }} key={bloc} square expanded={expanded === bloc} onChange={handleChange(bloc)}>
                            <ExpansionPanelSummary>
                                <Typography style={{ color: '#bda25a' }} key={bloc}>
                                    {bloc}
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                {scenes.filter(scene => sceneBloc[i18n.resolvedLanguage][bloc].includes(scene.title)).map(scene =>
                                    <a key={scene.name} onClick={toggleDrawer('left', false)}>
                                        <div className={"miniaVv"}>
                                            <img className="thumb" key={scene.name} alt={scene.title} src={scene.thumburl} style={{ margin: "10px" }} width={'60px'} height={'60px'} onClick={() => goToScene(scene.name)} />
                                            <p style={{ maxWidth: "100px", fontSize: "13px" }}>{scene.title}</p>
                                        </div>
                                    </a>
                                )}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))}
                    <Slideshow/>
                    {/*<Webcam/>*/}
                    <Intervenants/>
                    <div className="divider"></div>
                    <Contact/>
                    <div className={"logoSidebar"}>
                        <img src={logo} width={"150vw"} alt={"logo CDCL"}/>
                        <img src={delli} width={"150vw"} alt={"logo Dellizotti"}/>
                    </div>


                    {/*<Menu/>*/}
                    {/*<div style={{display: 'flex', justifyContent: 'center'}}>
                        <a href="https://www.thomas-piron.eu/" target="_blank"><img width="40px" src={webicon}/></a>
                        <a href="https://fr-fr.facebook.com/thomasetpiron" target="_blank"><img width="40px" src={fb}/></a>
                    </div>*/}
                </Drawer>
            </div>
            <div className="frame" style={{zIndex: "100", backgroundColor: 'rgba(255,255,255,0)', position: "absolute", width:'300px', padding: '20px', bottom: "7%", right: "36px"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    {/*<img width="150vw" alt="logo-CDCL" src={cdcl}/>
                    <img width="150vw" alt="logo-DL" src={delli}/>*/}
                    <h3>Domaine Petit Parc</h3>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button style={{fontFamily: "IntroBold",borderRadius: '0', color:'#FFF', background:'#bda25a', cursor: 'pointer', display: 'inline-block', fontSize: '.8em', height: '40px', lineHeight: '40px', margin: '0 .25em', padding: '0 10px', textAlign: 'center', textDecoration: 'none'}} onClick={toggleDrawer('left', true)}>
                        <Trans i18nKey="home.knowMore.cta.label">
                            En savoir plus
                        </Trans>
                    </Button>
                    <Button onClick={handleOpen} style={{fontFamily: "IntroBold",borderRadius: '0', color:'#FFF', background:'#676262', cursor: 'pointer', display: 'inline-block', fontSize: '.8em', height: '40px', lineHeight: '40px', margin: '0 .25em', padding: '0 10px', textAlign: 'center', textDecoration: 'none'}}>
                        <Trans i18nKey="home.contactUs.cta.label">
                            Contactez-nous
                        </Trans>
                    </Button>
                    <Modal
                        root={{overlay: {zIndex: '13'}}}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={open}
                        onClose={handleClose}
                        style={{outline: 'none'}}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{ timeout: 500,}}
                    >
                        <Fade in={open}>
                            <div id="modalContact"  className={classes.paper}>
                                <a style={{float: "right", marginTop: "5px", color: "#bda25a"}} width={"10px"}
                                   onClick={handleClose}><i className="material-icons"
                                                            style={{float: "right", cursor: "pointer"}}>close</i></a>
                                <img src={logo} style={{marginRight: "2%"}} width={'125vw'} alt={"logoCDCL"}/>
                                <img src={delli} style={{paddingBottom: "2%"}} width={'125vw'} alt={"logoDL"}/>
                                <h2>
                                    <Trans i18nKey="contact.form.title">
                                        Formulaire de contact
                                    </Trans>
                                </h2>
                                <div>
                                    <form id="idForm" onSubmit={sendMail} ref={formRef}>

                                        <label>
                                            <Trans i18nKey="contact.form.firstname.label">
                                                Prénom
                                            </Trans>
                                        </label>

                                        <input type="text" id="prenom" name="pnom"
                                               placeholder={t('contact.form.firstname.placeholder')}/>

                                        <label>
                                            <Trans i18nKey="contact.form.lastname.label">
                                                Nom
                                            </Trans>
                                        </label>

                                        <input type="text" id="nom" name="fnom"
                                               placeholder={t('contact.form.lastname.placeholder')}/>

                                        <label>
                                            <Trans i18nKey="contact.form.email.label">
                                                Email
                                            </Trans>
                                        </label>

                                        <input type="email" id="email" name="email"
                                               placeholder={t('contact.form.email.placeholder')}/>

                                        <label>
                                            <Trans i18nKey="contact.form.subject.label">
                                                Sujet
                                            </Trans>
                                        </label>

                                        <textarea id="subject" name="message"
                                                  placeholder={t('contact.form.subject.placeholder')}></textarea>

                                        <div style={{
                                            marginBottom: '2em',
                                            display: 'flex',
                                            flexDirection: 'row-reverse',
                                            alignItems: 'center'
                                        }}>
                                            <label htmlFor="rgpd_acceptance">
                                                <Trans i18nKey="contact.form.rgpd_acceptance.label.part1">Vous acceptez
                                                    que nous utilisions vos données conformément à nos </Trans><a
                                                href="https://www.cdclux.com/mentions-legales/" target="_blank"
                                                style={{color: '#676262'}}><Trans
                                                i18nKey="contact.form.rgpd_acceptance.label.part2">conditions
                                                générales</Trans></a></label>
                                            <input type="checkbox" id="rgpd_acceptance" name="rgpd_acceptance"
                                                   value="1"/>
                                        </div>

                                        <input id="sendButton" type="submit" style={{float: 'right'}}
                                               value={t("contact.form.submit.label")}/>

                                    </form>
                                </div>
                            </div>
                        </Fade>
                    </Modal>

                </div>
            </div>
        </div>
    );
}
