import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import logo from '../../assets/img/logo.png'
import cdclgold from "../../assets/img/cdclgold.png"
import delli from "../../assets/img/dellizotti.png"
import './style.css'
import axios from 'axios'
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import Fade from "@material-ui/core/Fade";
import {Trans, useTranslation} from "react-i18next";

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `50%`,
        left: `50%`,
        transform: `translate(-${top}%, -${left}%)`,
        outline: 'none !important'
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const formRef = React.createRef();

function sendMail(e) {
    e.preventDefault();
    const data = new FormData(formRef.current);
    const iterator = data.entries();
    let val;
    while (!(val = iterator.next()).done) {
        console.log(val.value)
    }
    axios.post(`${process.env.PUBLIC_URL}/controller/contact.php`, data)
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
    document.getElementById("idForm").reset();
    document.getElementById("sendButton").value = "Message envoyé !"
}

export default function Contact() {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {

        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const {t} = useTranslation();

    return (
        <div>
            <button id="contactButton" style={{width: "300px"}} type="button" onClick={handleOpen}>
                <Trans i18nKey="menu.contactUs.cta.label">
                    Contactez-nous
                </Trans>
            </button>
            <Modal
                root={{overlay: {zIndex: '13'}}}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
                style={{outline: 'none'}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500,}}
            >
                <Fade in={open}>
                    <div id="modalContact" style={modalStyle} className={classes.paper}>
                        <a style={{float: "right", marginTop: "5px", color: "#bda25a"}} width={"10px"} onClick={handleClose}><i className="material-icons" style={{float: "right", cursor: "pointer"}}>close</i></a>
                        <img src={logo} style={{marginRight: '2%'}} width={'125vw'} alt={"logoCDCL"}/>
                        <img src={delli} style={{paddingBottom: "2%"}} width={'125vw'} alt={"logoDL"}/>
                        <h2>
                            <Trans i18nKey="contact.form.title">
                                Formulaire de contact
                            </Trans>
                        </h2>
                        <div>
                            <form id="idForm" onSubmit={sendMail} ref={formRef}>
                                <label>
                                    <Trans i18nKey="contact.form.firstname.label">
                                        Prénom
                                    </Trans>
                                </label>

                                <input type="text" id="prenom" name="pnom" placeholder={t('contact.form.firstname.placeholder')} />

                                <label>
                                    <Trans i18nKey="contact.form.lastname.label">
                                        Nom
                                    </Trans>
                                </label>

                                <input type="text" id="nom" name="fnom" placeholder={t('contact.form.lastname.placeholder')} />

                                <label>
                                    <Trans i18nKey="contact.form.email.label">
                                        Email
                                    </Trans>
                                </label>

                                <input type="email" id="email" name="email" placeholder={t('contact.form.email.placeholder')} />

                                <label>
                                    <Trans i18nKey="contact.form.subject.label">
                                        Sujet
                                    </Trans>
                                </label>

                                <textarea id="subject" name="message" placeholder={t('contact.form.subject.placeholder')} ></textarea>

                                <div style={{ marginBottom: '2em', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                                    <label htmlFor="rgpd_acceptance">
                                        <Trans i18nKey="contact.form.rgpd_acceptance.label.part1">Vous acceptez que nous utilisions vos données conformément à nos </Trans><a href="https://www.cdclux.com/mentions-legales/" target="_blank" style={{ color:'#676262'}}><Trans i18nKey="contact.form.rgpd_acceptance.label.part2">conditions générales</Trans></a></label>
                                    <input type="checkbox" id="rgpd_acceptance" name="rgpd_acceptance" value="1"/>
                                </div>

                                <input id="sendButton" type="submit" style={{float: 'right'}} value={t("contact.form.submit.label")}/>
                            </form>

                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
