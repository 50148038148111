import React, {Component} from 'react';

class Menu extends Component {
    render() {
        return (
            <div>
                <a href="../">
                    <button id="infoButton" style={{color: '#E53118', maxWidth:'250px', textAlign: 'center'}} type="button">
                        Découvrir d'autres modèles
                    </button>
                </a>
            </div>
        );
    }
}

export default Menu;